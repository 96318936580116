import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18next';
import MessageModal from '../Modals/MessageModal';

const MapBoxLoader = ({ children, mapsAccessToken }) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    if (!mapsAccessToken) {
      // eslint-disable-next-line no-console
      console.warn(`
        MapBox access token not found. Did you provided correct access token from MapBox account?
        Ensure you provided valid access token from MapBox account and load maps when settings from API has been already fetched.
      `);
      setError(true);
      return () => {};
    }

    const js = document.createElement('script');
    const css = document.createElement('link');
    const geocoderJs = document.createElement('script');

    const promises = [
      new Promise((rs) => {
        js.src = 'https://api.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.js';
        js.onload = rs;
        document.body.appendChild(js);
      }),
      new Promise((rs) => {
        geocoderJs.src = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.2/mapbox-gl-geocoder.min.js';
        geocoderJs.onload = rs;
        document.body.appendChild(geocoderJs);
      }),
      new Promise((rs) => {
        css.href = 'https://api.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.css';
        css.rel = 'stylesheet';
        css.onload = rs;
        document.head.appendChild(css);
      }),
    ];

    Promise.all(promises).then(() => {
      window.mapboxgl.accessToken = mapsAccessToken;
      setReady(true);
    });

    return () => {
      document.body.removeChild(js);
      document.body.removeChild(geocoderJs);
      document.head.removeChild(css);
    };
  }, [mapsAccessToken]);

  return (
    <>
      {ready && children}
      {error && (
        <MessageModal
          title={i18n.t('CONTENT.genericError')}
          onOk={() => setError(null)}
          onClose={() => setError(null)}
        >
          <div className="c-mapbox__error">
            {i18n.t('GLOBAL.mapLoadError')}
          </div>
        </MessageModal>
      )}
    </>
  );
};

MapBoxLoader.propTypes = {
  mapsAccessToken: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
  mapsAccessToken: state.global.websiteSettings.mapsAccessToken,
});

export default connect(mapStateToProps)(MapBoxLoader);
