import {
  ADD_CONTENT,
  ADD_COMPONENT,
  CLEAR_LAST_ADDED_CONTENT_ID,
  ADD_COMPONENT_TO_COLUMN,
} from '../actions/contentAdd';
import { MOVE_COMPONENT, MOVE_CONTENT } from '../actions/contentMove';
import {
  CONTENT_SAVE_SUCCESS,
  CONTENT_SAVE_REQUEST,
  CONTENT_SAVE_FAILURE,
} from '../actions/contentSave';
import { RESTORE_CONTENT } from '../actions/contentRestore';
import {
  CLOSE_DELETE_CONTENT,
  DELETE_CONTENT,
  OPEN_DELETE_CONTENT,
  DELETE_COMPONENT,
} from '../actions/contentDelete';
import {
  CLOSE_DELETE_MEDIA,
  OPEN_DELETE_MEDIA,
} from '../actions/mediaDelete';
import {
  CLOSE_RENAME_MEDIA,
  OPEN_RENAME_MEDIA,
} from '../actions/mediaConfig';
import {
  SHOW_CONTENT_COMPONENT_BY_TYPE,
  HIDE_CONTENT_COMPONENT_BY_TYPE,
} from '../actions/contentComponentToggle';
import {
  SET_CONTENT_OPTION,
  CLEAR_CONTENT_OPTION,
  SET_COMPONENT_OPTION,
  CLEAR_COMPONENT_OPTION,
} from '../actions/contentConfig';
import {
  OPEN_CONTENT_EDITOR,
  CLOSE_CONTENT_EDITOR,
  EDITOR_UPDATE,
} from '../actions/contentEditor';
import {
  OPEN_MEDIA_PICKER,
  CLOSE_MEDIA_PICKER,
  OPEN_MEDIA_MANAGER,
  CLOSE_MEDIA_MANAGER,
  SET_MEDIA_PICKER_TYPE,
} from '../actions/mediaManager';
import {
  OPEN_GOOGLEMAPS_EDITOR,
  CLOSE_GOOGLEMAPS_EDITOR,
} from '../actions/googleMaps';
import {
  OPEN_LINK_PICKER,
  CLOSE_LINK_PICKER,
} from '../actions/links';
import { OPEN_CONTENT_LAYOUT, CLOSE_CONTENT_LAYOUT } from '../actions/contentLayout';
import { SET_CONTENT_COLUMN_COUNT } from '../actions/contentColumns';
import {
  CLOSE_CONTENT_MODALS,
  CONTENT_REQUEST,
  CONTENT_SUCCESS,
  CONTENT_FAILURE,
  PAGE_HTML_CONTENT_SUCCESS,
  SET_ACTIVE_SECTION,
} from '../actions/content';
import {
  OPEN_CODE_EDITOR,
  CSS_EDITOR_FETCH_SUCCESS,
  JS_EDITOR_FETCH_SUCCESS,
  CLEAR_CODE_EDITOR_CONTENT,
  OPEN_CODE_EDITOR_SAVE_CONFIRMATION,
  CLOSE_CODE_EDITOR_SAVE_CONFIRMATION,
} from '../actions/codeEditor';
import {
  DYNAMIC_CONTENT_SUCCESS,
} from '../actions/dynamicContent';
import { CLONE_CONTENT } from '../actions/contentClone';
import { MODULES_SUCCESS } from '../actions/modules';

const initialState = {
  addModule: false,
  contentEditorContentId: null,
  contentViewOptions: {}, // content (aka section ) view options (example: slider edit view is open)
  deleteContentProps: false,
  deleteMediaProps: false,
  googleMapsEditorContentId: null,
  googleMapsEditorComponentId: null,
  isContentLoading: false,
  isModulesLoaded: false,
  isContentEditorOpen: false,
  isContentLayoutModalOpen: false,
  isDeleteContentModalOpen: false,
  isDeleteMediaModalOpen: false,
  isGoogleMapsModalOpen: false,
  isLinkPickerModalOpen: false,
  isMediaManagerModalOpen: false,
  isMediaPickerModalOpen: false,
  isCodeEditorConfirmModalOpen: false,
  isModified: false,
  isSaving: false,
  isRenameMediaModalOpen: false,
  layoutModalContentId: null,
  linkPickerValue: false,
  linkPickerLang: null,
  lastAddedContentId: null,
  mediaManagerContentId: null,
  mediaManagerContentType: null,
  mediaManagerSelection: false, // last selected media after closing the MediaManager
  mediaPickerContentId: null,
  mediaPickerMedia: null,
  renameMediaProps: false,
  isCodeEditorOpen: false,
  codeEditorLanguage: null,
  dynamicContent: {},
  isAddingContent: false,
  pageHtmlContent: null,
  activeSectionId: null,
};

const content = (state = initialState, action) => {
  switch (action.type) {
    case CONTENT_REQUEST: {
      return {
        ...state,
        isContentLoading: true,
      };
    }
    case CONTENT_SUCCESS: {
      return {
        ...state,
        isContentLoading: false,
      };
    }
    case CONTENT_FAILURE: {
      return {
        ...state,
        isContentLoading: false,
      };
    }
    case MODULES_SUCCESS: {
      return {
        ...state,
        isModulesLoaded: true,
      };
    }
    case ADD_CONTENT: {
      const addModule = { ...action.value };
      return {
        ...state,
        isModified: true,
        lastAddedContentId: addModule.id,
        addModule,
      };
    }
    case CLEAR_LAST_ADDED_CONTENT_ID: {
      return {
        ...state,
        lastAddedContentId: null,
      };
    }
    case MOVE_CONTENT: {
      return {
        ...state,
        isModified: true,
      };
    }
    case CONTENT_SAVE_SUCCESS: {
      return {
        ...state,
        isModified: false,
        isSaving: false,
      };
    }
    case CONTENT_SAVE_FAILURE: {
      return {
        ...state,
        isModified: true,
        isSaving: false,
      };
    }
    case CONTENT_SAVE_REQUEST: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case RESTORE_CONTENT: {
      return {
        ...state,
        isModified: false,
      };
    }
    case OPEN_DELETE_CONTENT: {
      return {
        ...state,
        isDeleteContentModalOpen: true,
        deleteContentProps: action.value,
      };
    }
    case CLOSE_DELETE_CONTENT: {
      return {
        ...state,
        isDeleteContentModalOpen: false,
        deleteContentProps: false,
      };
    }
    case OPEN_DELETE_MEDIA: {
      return {
        ...state,
        isDeleteMediaModalOpen: true,
        deleteMediaProps: action.value,
      };
    }
    case CLOSE_DELETE_MEDIA: {
      return {
        ...state,
        isDeleteMediaModalOpen: false,
        deleteMediaProps: false,
      };
    }
    case OPEN_RENAME_MEDIA: {
      return {
        ...state,
        isRenameMediaModalOpen: true,
        renameMediaProps: action.value,
      };
    }
    case CLOSE_RENAME_MEDIA: {
      return {
        ...state,
        isRenameMediaModalOpen: false,
        renameMediaProps: false,
      };
    }
    case OPEN_MEDIA_PICKER: {
      const { id, type } = action.value;
      return {
        ...state,
        isMediaPickerModalOpen: true,
        mediaPickerContentId: id,
        mediaPickerMedia: action.value,
        mediaManagerContentType: type,
      };
    }
    case CLOSE_MEDIA_PICKER: {
      return {
        ...state,
        isMediaPickerModalOpen: false,
        mediaPickerContentId: null,
        mediaPickerMedia: null,
        mediaManagerContentType: null,
      };
    }
    case SET_MEDIA_PICKER_TYPE: {
      const type = action.value;
      const allowedTypes = ['image', 'document'];

      if (allowedTypes.includes(type)) {
        return {
          ...state,
          mediaManagerContentType: type,
        };
      }

      return {
        ...state,
        mediaManagerContentType: null,
      };
    }
    case OPEN_MEDIA_MANAGER: {
      const { id, type } = action.value;
      return {
        ...state,
        isMediaManagerModalOpen: true,
        mediaManagerContentId: id,
        mediaManagerContentType: type,
        mediaManagerSelection: false,
      };
    }
    case CLOSE_MEDIA_MANAGER: {
      const { value } = action;
      return {
        ...state,
        isMediaManagerModalOpen: false,
        mediaManagerContentId: null,
        mediaManagerContentType: null,
        mediaManagerSelection: value,
      };
    }
    case OPEN_CONTENT_LAYOUT: {
      const { id, isAddingContent } = action.value;
      return {
        ...state,
        isContentLayoutModalOpen: true,
        layoutModalContentId: id,
        isAddingContent: isAddingContent || false,
      };
    }
    case CLOSE_CONTENT_LAYOUT: {
      return {
        ...state,
        isContentLayoutModalOpen: false,
        layoutModalContentId: null,
        isAddingContent: false,
      };
    }
    case OPEN_CONTENT_EDITOR: {
      const { id } = action.value;
      return {
        ...state,
        isContentEditorOpen: true,
        isCodeEditorOpen: false,
        contentEditorContentId: id,
      };
    }
    case CLOSE_CONTENT_EDITOR: {
      return {
        ...state,
        isContentEditorOpen: false,
        contentEditorContentId: null,
      };
    }
    case OPEN_GOOGLEMAPS_EDITOR: {
      const { id, componentId = null } = action.value;
      return {
        ...state,
        isGoogleMapsModalOpen: true,
        googleMapsEditorContentId: id,
        googleMapsEditorComponentId: componentId,
      };
    }
    case CLOSE_GOOGLEMAPS_EDITOR: {
      return {
        ...state,
        isGoogleMapsModalOpen: false,
        googleMapsEditorContentId: null,
        googleMapsEditorComponentId: null,
      };
    }
    case OPEN_LINK_PICKER: {
      const { link, lang } = action.value;

      return {
        ...state,
        isLinkPickerModalOpen: true,
        linkPickerValue: link,
        linkPickerLang: lang,
      };
    }
    case CLOSE_LINK_PICKER: {
      return {
        ...state,
        isLinkPickerModalOpen: false,
        linkPickerLang: null,
        linkPickerValue: action.value,
      };
    }
    case CLOSE_CONTENT_MODALS: {
      return {
        ...state,
        linkPickerLang: null,
        contentEditorContentId: null,
        deleteContentProps: false,
        deleteMediaProps: false,
        googleMapsEditorContentId: null,
        isContentEditorOpen: false,
        isContentLayoutModalOpen: false,
        isDeleteContentModalOpen: false,
        isDeleteMediaModalOpen: false,
        isGoogleMapsModalOpen: false,
        isLinkPickerModalOpen: false,
        isMediaManagerModalOpen: false,
        isMediaPickerModalOpen: false,
        layoutModalContentId: null,
        linkPickerValue: false,
        mediaManagerContentId: null,
        mediaManagerContentType: null,
        mediaManagerSelection: false,
        mediaPickerContentId: null,
        mediaPickerMedia: null,
        isCodeEditorOpen: false,
        isCodeEditorConfirmModalOpen: false,
        codeEditorLanguage: null,
        codeEditorContent: null,
      };
    }
    case ADD_COMPONENT:
    case ADD_COMPONENT_TO_COLUMN:
    case MOVE_COMPONENT:
    case CLONE_CONTENT:
    case CLEAR_COMPONENT_OPTION:
    case CLEAR_CONTENT_OPTION:
    case DELETE_COMPONENT:
    case DELETE_CONTENT:
    case HIDE_CONTENT_COMPONENT_BY_TYPE:
    case SET_CONTENT_COLUMN_COUNT:
    case SET_CONTENT_OPTION:
    case SHOW_CONTENT_COMPONENT_BY_TYPE:
    {
      return {
        ...state,
        isModified: true,
      };
    }
    case SET_COMPONENT_OPTION: {
      // those settings are auto-filled when component is mounded
      // no need to change isModified flag
      const silentChanges = ['mediaId', 'imageType'];

      if (silentChanges.includes(action.value.option)) {
        return state;
      }

      return {
        ...state,
        isModified: true,
      };
    }
    case EDITOR_UPDATE: {
      return {
        ...state,
        isModified: true,
      };
    }
    // opening the code (CSS & JS) editor
    case OPEN_CODE_EDITOR:
    {
      return {
        ...state,
        isCodeEditorOpen: true,
        isContentEditorOpen: false,
        codeEditorLanguage: action.codeLanguage,
      };
    }
    case JS_EDITOR_FETCH_SUCCESS:
    case CSS_EDITOR_FETCH_SUCCESS:
    {
      return {
        ...state,
        codeEditorContent: typeof action.response !== 'undefined' && action.response ? action.response : '',
      };
    }
    case CLEAR_CODE_EDITOR_CONTENT:
    {
      return {
        ...state,
        codeEditorContent: null,
      };
    }
    case OPEN_CODE_EDITOR_SAVE_CONFIRMATION: {
      return {
        ...state,
        isCodeEditorConfirmModalOpen: true,
      };
    }
    case CLOSE_CODE_EDITOR_SAVE_CONFIRMATION: {
      return {
        ...state,
        isCodeEditorConfirmModalOpen: false,
      };
    }
    case DYNAMIC_CONTENT_SUCCESS: {
      const { endpoint, response } = action;

      return {
        ...state,
        dynamicContent: {
          ...state.dynamicContent,
          [endpoint]: (typeof response !== 'undefined' && response ? response : ''),
        },
      };
    }
    case PAGE_HTML_CONTENT_SUCCESS: {
      return {
        ...state,
        pageHtmlContent: action.response,
      };
    }
    case SET_ACTIVE_SECTION: {
      return {
        ...state,
        activeSectionId: action.value,
      };
    }
    default:
      return state;
  }
};

export default content;
