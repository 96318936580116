import ContentComponentHr from './ContentComponentHr';
import ContentComponentButton from './ContentComponentButton';
import ContentComponentImage from './ContentComponentImage';
import ContentComponentGallery from './ContentComponentGalleryImage';
import ContentComponentText from './ContentComponentText';
import ContentComponentTitle from './ContentComponentTitle';
import ContentComponentSubtitle from './ContentComponentSubtitle';
import ContentComponentHtml from './ContentComponentHtml';
import ContentComponentGoogleMaps from './ContentComponentGoogleMaps';
import ContentComponentSliderImage from './ContentComponentSliderImage';
import ContentComponentVideo from './ContentComponentVideo';
import DynamicContentComponent from './ContentComponentDynamic';

export default {
  button: ContentComponentButton,
  buttonSecondary: ContentComponentButton,
  googleMaps: ContentComponentGoogleMaps,
  hr: ContentComponentHr,
  html: ContentComponentHtml,
  image: ContentComponentImage,
  galleryImage: ContentComponentGallery,
  subtitle: ContentComponentSubtitle,
  text: ContentComponentText,
  title: ContentComponentTitle,
  sliderImage: ContentComponentSliderImage,
  video: ContentComponentVideo,
  dynamic: DynamicContentComponent,
};
