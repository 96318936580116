/* eslint-disable react/no-danger */
/* global mapboxgl */
import React, {
  useRef, useState, useLayoutEffect, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { htmlDecode } from 'htmlencode';
import MapBoxLoader from '../../MapBox/MapBoxLoader';

const MapMarker = ({
  children, longitude, latitude, mapRef,
}) => {
  const elementRef = useRef(null);
  const markerRef = useRef(null);

  useLayoutEffect(() => {
    markerRef.current = new mapboxgl.Marker(elementRef.current)
      .setLngLat([longitude, latitude])
      .addTo(mapRef.current);

    return () => {
      if (markerRef.current) {
        markerRef.current.remove();
      }
    };
  }, [longitude, longitude]);

  return (
    <div ref={elementRef}>
      {children}
    </div>
  );
};

MapMarker.propTypes = {
  children: PropTypes.node.isRequired,
  longitude: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  latitude: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  mapRef: PropTypes.shape({
    current: PropTypes.any.isRequired,
  }).isRequired,
};

const ContentComponentMaps = (props) => {
  const containerRef = useRef();
  const mapRef = useRef();
  const [ready, setReady] = useState(false);

  const {
    address,
    classes,
    containerClassName,
    latitude,
    longitude,
    mapStyle,
    mapZoom,
    markerTitle: markerTitleEncoded,
    markerValue,
    type,
  } = props;
  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
    `c-${type}--${mapStyle}`,
  );
  const markerTitle = htmlDecode(markerTitleEncoded);

  const styleSet = mapStyle === 'grayscale'
    ? 'mapbox://styles/mapbox/light-v10'
    : 'mapbox://styles/mapbox/streets-v11';

  useEffect(() => {
    const params = {
      longitude,
      latitude,
      mapZoom: mapZoom || 15,
    };

    Object
      .keys(params)
      .every(item => {
        if (typeof params[item] === 'undefined') {
          // eslint-disable-next-line no-console
          console.warn(`Required attribute is empty: ${item}.`);
        }

        return item;
      });

    mapRef.current = new mapboxgl.Map({
      container: containerRef.current,
      style: styleSet,
      center: [params.longitude, params.latitude],
      zoom: params.mapZoom,
    });
    setReady(true);

    return () => {
      mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setStyle(styleSet);
    }
  }, [styleSet]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [
          longitude,
          latitude,
        ],
        essential: true,
      });
    }
  }, [longitude, latitude]);

  return (
    <div
      ref={containerRef}
      className={componentClasses}
      data-lat={latitude}
      data-lon={longitude}
      data-address={address}
      data-markervalue={markerValue}
      data-markertitle={markerTitle}
      data-map-style={mapStyle}
      data-zoom={mapZoom}
    >
      {ready && markerValue && (
        <MapMarker
          mapRef={mapRef}
          latitude={latitude}
          longitude={longitude}
        >
          <div className="c-mapbox-marker">
            <div className="c-mapbox-marker__content" dangerouslySetInnerHTML={{ __html: markerTitle }} />
            <svg className="c-mapbox-marker__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
            </svg>
          </div>
        </MapMarker>
      )}
    </div>
  );
};

ContentComponentMaps.propTypes = {
  latitude: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  longitude: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  markerTitle: PropTypes.string.isRequired,
  mapZoom: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  mapStyle: PropTypes.string,
  address: PropTypes.string.isRequired,
  classes: PropTypes.string,
  containerClassName: PropTypes.string.isRequired,
  markerValue: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

ContentComponentMaps.defaultProps = {
  mapStyle: '',
  classes: '',
};

export default (props => (
  <MapBoxLoader>
    <ContentComponentMaps {...props} />
  </MapBoxLoader>
));
